import React from 'react';
import PropTypes from 'prop-types';


const VideoBackground = ({ videoSrc, title }) => {
    const containerStyle = {
      position: 'relative',
      width: '100%',
      height: '100vh', 
      overflow: 'hidden',
    };
  
    const videoStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      zIndex: -1, 
    };
  
    const titleStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1, 
      color: 'white', 
      textAlign: 'center',
      fontSize: '2rem', 
    }
  return (
    <div style={containerStyle}>
       {videoSrc && (
        <video autoPlay loop muted className="background-video">
          <source src={process.env.PUBLIC_URL + videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      {title && <h1 style={titleStyle}>{title}</h1>}
    </div>
  );
};

VideoBackground.propTypes = {
  videoSrc: PropTypes.string,
  title: PropTypes.string,
};

export default VideoBackground;
