import React from 'react';
import {Typography, Box, Button} from  '@mui/material';

import { styled } from '@mui/system';

const StyledButton = styled(Button)({
    color: 'white', // Azul grisáceo un poco más oscuro
    margin: 0,
    borderRadius :0,
    border: "1px solid white"
    });

/**
 * Data {
 *  tittle
 *  description
 *  buttonText
 *  buttonAction
 *  backgroundColor
 *  backgroundImage
 * }
 */

function LandingElement({data}) {
    const tittle = data.tittle?
    <Typography  variant="h5" component="h3" sx={{fontWeight: 'bold', marginBottom:'1rem'}} >
        {data.tittle}
    </Typography>
    :
    false;

    const description = data.description?data.description.constructor === Array?
    Array.from(data.description).map((d, index) => (
        <Typography variant="h6" component="p">
            {d}
        </Typography>
     )):    
    <Typography variant="h6" component="p">
        {data.description}
    </Typography>
    :
    false;

    const button = data.buttonText?
    <StyledButton variant="outlined" sx={description?{marginTop:'2rem'}:{}} onClick={data.buttonAction}>
        {data.buttonText}
    </StyledButton>
    :
    false;

    const extraStyles = { height:261, padding: "7% 10%"};

    const StyledBox = styled(Box)(({ theme }) => ({
        ...extraStyles,
        backgroundImage: data.backgroundImage ? `url(${data.backgroundImage})` : undefined,
        backgroundColor: data.backgroundColor || undefined,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    }));

  return (
    <StyledBox >
        {tittle}
        {description}
        {button}
    </StyledBox>
  );
}

export default LandingElement;
