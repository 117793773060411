import React, { useState, useEffect } from 'react';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { AppBar, Toolbar, IconButton, Typography, Box, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import './Menu.css';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';

const CustomA = styled('a')({
  backgroundColor: '#f0f0f0', // Azul grisáceo un poco más oscuro
  margin: 0,
  border: '1px solid #f0f0f0',
  padding: 0,
  width: 40,
  height: 40,
  borderRadius: 40,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'width 0.3s, height 0.3s',
  overflow: 'hidden', // Ensures the image doesn't overflow the border radius

  '&:hover': {
    border: '1px solid #4a5f76'
  },

  img: {
    margin:0,
    padding:0,
    border:0,
    backgroundColor: '#f0f0f0',
    display: 'block',
  },
});

// Functional component for rendering an image link
const ImageLink = ({ href, imgSrc, altText, title, ariaLabel }) => (
  <CustomA href={href} title={title} aria-label={ariaLabel}>
    <img src={imgSrc} alt={altText} />
  </CustomA>
);



function Menu({menuItems =  [], isMainPage= false}) {

  const navigate = useNavigate();
  
  const [isSticky, setIsSticky] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleMenuClick = (path, section) => {
    const element = document.getElementById(section);
    if (element) {
      const yOffset = -70; // Ajusta este valor a la altura de tu menú
      const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
    setDrawerOpen(false); // Cierra el menú cuando se hace clic en un enlace
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawer = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuItems.map((item,index) => (
          <ListItem button key={index} onClick={() => handleMenuClick('/', item.section)}>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar position="sticky" className={`menu ${isSticky ? 'sticky' : 'initState'}`} style={{ width: '100%' }}>
      <Toolbar>
        <Typography variant="h6" className="mr-auto" onClick={() => isMainPage ? handleMenuClick('/', 'header') : navigate('/')} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          <Box className="logo-container">
            <img src="/logoDMD.png" alt="Logo" className="logo" onClick={() => isMainPage ? handleMenuClick('/', 'header') : navigate('/')}/>
          </Box>
        </Typography>

        <ImageLink href="mailto:name@email.com" 
          imgSrc="/mail.webp"
          altText="Email"
          title="Send an email"
          ariaLabel="Send an email"
        />
        <ImageLink href="https://www.linkedin.com/"
          imgSrc="/linkedin.webp"
          altText="LinkedIn"
          title="Visit LinkedIn Profile"
          ariaLabel="Visit LinkedIn Profile"
        />

        <LanguageSelector />
        <IconButton color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>
        
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawer}
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}

export default Menu;
