const SchoolsData = [
    {
      name: 'Colegio 1',
      description: 'schoolDescription1',
      logo: '/images/school1.png',
      link: '/school1',
    },
    {
      name: 'Colegio 2',
      description: 'schoolDescription2',
      logo: '/images/school2.png',
      link: '/school2',
    },
    {
      name: 'Colegio 3',
      description: 'schoolDescription3',
      logo: '/images/school3.png',
      link: '/school3',
    },
    {
      name: 'Colegio 4',
      description: 'schoolDescription4',
      logo: '/images/school4.png',
      link: '/school4',
    },
    {
      name: 'Colegio 5',
      description: 'schoolDescription5',
      logo: '/images/school5.png',
      link: '/school5',
    },
  ];
  export default SchoolsData;