import React from 'react';
import { createBrowserRouter, RouterProvider, Route } from 'react-router-dom';
import App from './App/App';
import ApplicationForm from './components/ApplicationForm/ApplicationForm';
import About from './About/About'

import LeaderShip from './Leadership/Leadership';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        <div className="content">
          <App />
        </div>
      </>
    ),
  },
  {
    path: '/work-in-usa',
    element: (
      <>
        <div className="content">
          <ApplicationForm />
        </div>
      </>
    ),
  },
  {
    path: '/about',
    element: (
      <>
        <div className="content">
          <About />
        </div>
      </>
    ), 
  },
  {
    path: '/leadership',
    element: (
      <>
        <div className="content">
          <LeaderShip />
        </div>
      </>
    ), 
  }
]);

export default function AppRouter() {
  return <RouterProvider router={router} />;
}
