import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Typography, Box, Card, CardContent, CardMedia, CardActions, Button } from '@mui/material';
import { styled } from '@mui/system';
import './Schools.css';
import ScoolsData from './SchoolsData.js'

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#4a5f76', // Azul grisáceo un poco más oscuro
  color: '#ffffff', // Blanco
  borderRadius : 0,
  transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
  width:240
}));

function Schools({ fullscreen, backgroundColor }) {
  const { t } = useTranslation("Schools");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % (ScoolsData.length-2));
        setIsTransitioning(false);
      }, 500); // La duración debe coincidir con la animación en CSS
    }, 5000); // Cambia el colegio cada 5 segundos
    return () => clearInterval(interval);
  }, []);

  const classNames = `schools-section ${fullscreen ? 'fullscreen' : ''}`;

  return (
    <div className={classNames} style={{ backgroundColor: backgroundColor }}>
      <Typography variant="h4" component="h2" gutterBottom>
        {t('affiliatedSchools')}
      </Typography>
      <Box className="carousel-container">
        <Box className='carousel' sx={{ transform: `translateX(-${currentIndex * 33.33}%)` }}>
          {ScoolsData.map((school, index) => (
            <Box className="carousel-item" key={index}>
              <StyledCard>
                <CardMedia
                  component="img"
                  alt={`${school.name} logo`}
                  height="240"
                  image={school.logo}
                  title={school.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {school.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t(school.description)}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" href={school.link}>
                    Learn More
                  </Button>
                </CardActions>
              </StyledCard>
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
}

export default Schools;
