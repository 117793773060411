import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const rJsonComponent = (name,lang) => require('../components/'+name+'/locales/'+lang+'.json');

const resources = {
  en: {
    ApplicationForm: rJsonComponent("ApplicationForm","en"),
    Description: rJsonComponent("Description","en"),
    Experiences: rJsonComponent ("Experiences","en"),
    Footer: rJsonComponent("Footer","en"),
    WhyChooseUs: rJsonComponent("WhyChooseUs","en"),
    Process: rJsonComponent("Process","en"),
    Schools: rJsonComponent("Schools","en"),
  },
  es: {
    ApplicationForm: rJsonComponent("ApplicationForm","es"),
    Description: rJsonComponent("Description","es"),
    Experiences: rJsonComponent ("Experiences","es"),
    Footer: rJsonComponent("Footer","es"),
    WhyChooseUs: rJsonComponent("WhyChooseUs","es"),
    Process: rJsonComponent("Process","es"),
    Schools: rJsonComponent("Schools","en"),
  },
};

i18n
  .use(initReactI18next) // Pasa i18n al framework de react-i18next
  .init({
    resources,
    lng: 'en', // Idioma por defecto
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // React ya se encarga de escapar los valores
    }
  });

export default i18n;