import { styled } from '@mui/system';

const FullSizeSectionon = styled('section')({
  margin:0,
  border:0,
  display: "flex",
  flexDirection: "column",
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  textAlign: 'center',
  color:"white"
});

export default FullSizeSectionon;