import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  InputLabel
} from '@mui/material';
import { styled } from '@mui/system';
import './ApplicationForm.css';

const CustomFileInput = styled('input')({
  display: 'none',
});

const CustomButton = styled(Button)({
  backgroundColor: '#4a5f76', /* Azul grisáceo un poco más oscuro */
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#657b93', /* Azul grisáceo más claro al pasar el cursor */
  }
});

const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#4a5f76',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#4a5f76',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ccc',
    },
    '&:hover fieldset': {
      borderColor: '#4a5f76',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#4a5f76',
    },
  },
});

function ApplicationForm() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    resume: null,
    coverLetter: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, resume: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes manejar el envío del formulario, por ejemplo, enviándolo a una API
    console.log(formData);
  };

  return (
    <Container id="application-form" className="py-5">
      <Box display="flex" justifyContent="center">
        <Box width="100%" p={3} className="form-wrapper">
          <Typography variant="h4" component="h2" align="center" gutterBottom>
            {t("formTitle")}
          </Typography>
          <form onSubmit={handleSubmit}>
            <CustomTextField
              fullWidth
              margin="normal"
              label={t("firstName")}
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            <CustomTextField
              fullWidth
              margin="normal"
              label={t("lastName")}
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            <CustomTextField
              fullWidth
              margin="normal"
              label={t("email")}
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <Box my={2}>
              <InputLabel>{t("resume")}</InputLabel>
              <label htmlFor="resume-upload">
                <CustomFileInput
                  accept=".pdf,.doc,.docx"
                  id="resume-upload"
                  type="file"
                  name="resume"
                  onChange={handleFileChange}
                  required
                />
                <CustomButton variant="contained" component="span">
                  {t("selectFile")}
                </CustomButton>
                {formData.resume && <Typography variant="body2" className="file-name">{formData.resume.name}</Typography>}
              </label>
            </Box>
            <CustomTextField
              fullWidth
              margin="normal"
              label={t("coverLetter")}
              name="coverLetter"
              value={formData.coverLetter}
              onChange={handleChange}
              multiline
              rows={4}
              required
            />
            <CustomButton variant="contained" color="primary" type="submit" fullWidth className="mt-3">
              {t("submit")}
            </CustomButton>
          </form>
        </Box>
      </Box>
    </Container>
  );
}

export default ApplicationForm;
