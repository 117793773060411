import React from 'react';
import { useTranslation } from 'react-i18next';
import LandingComposition from '../../util/LandingComposition.js';
import { useNavigate } from 'react-router-dom';
import FullSizeSection from '../../styles/FullSizeSection.js';

function Description({ backgroundColor }) {
  const { t } = useTranslation("Description");
  const navigate = useNavigate();
  let data = [{
    tittle : t("descriptionTittle"),
    description :t("descriptionMessage"),
    buttonText: t("buttonText"),
    backgroundImage: "/images/about3.webp",
    buttonAction: ()=> { navigate('/about'); }
  }];
  return (
    <FullSizeSection id="description" style={{ backgroundColor }}>
      <LandingComposition LandingElementsData={data} />
    </FullSizeSection>
  );
}

export default Description;
