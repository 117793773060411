import React from 'react';
import './Footer.css'

function Footer() {
  return (
    <footer id="footer">
      <h2>Contacto</h2>
      <p>Correo electrónico: contacto@proyecto.com</p>
      <p>Síguenos en nuestras redes sociales:</p>
      <ul>
        <li><a href="https://facebook.com">Facebook</a></li>
        <li><a href="https://twitter.com">Twitter</a></li>
        <li><a href="https://instagram.com">Instagram</a></li>
      </ul>
    </footer>
  );
}

export default Footer;