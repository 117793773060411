import React from 'react';
import './WhyChooseUs.css';
import {Typography} from  '@mui/material';
import { useTranslation } from 'react-i18next';
import LandingComposition from '../../util/LandingComposition.js';
import FullSizeSection from '../../styles/FullSizeSection.js';


function WhyChooseUs({ backgroundColor }) {
  const { t } = useTranslation("WhyChooseUs");
  let data = [{
    tittle : t("tittle1"),
    description :t("description1"),
  },{
    tittle : t("tittle2"),
    description :t("description2"),
  },{tittle : t("tittle3"),
    description :t("description3"),
  }];
  let dataObjetives = [
    {
      tittle: t("tittle5"),
      backgroundImage: "/images/Mission.webp"

  
    },
    {
      description :[t("description51"),t("description52"),t("description53")],
  //    buttonText: t("tittle5"),
  //    buttonAction: ()=>{console.log("hola")},
    }
  ]

  //      <Typography  variant="h5" component="h3" sx={{fontWeight: 'bold', marginBottom:'1rem'}} >
  // {t("whychooseus")}
  // </Typography>

  return (
    
    <FullSizeSection id="whychooseus" style={{ backgroundColor }}>

      <LandingComposition LandingElementsData={data} />
      <LandingComposition LandingElementsData={dataObjetives} />
      
    </FullSizeSection>
  );
}

export default WhyChooseUs;
