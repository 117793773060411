import React from 'react';
import {Grid} from  '@mui/material';
import LandingElement from './LandingElement';
import { styled } from '@mui/system';

const StyledGrid = styled(Grid)({
  width: "100%", height: "100%"
    });

function LandingComposition({LandingElementsData = []}) {
  return (
    <StyledGrid container spacing={0} direction="row">
      {Array.from(LandingElementsData).map((leData, index) => (
         <Grid  xs={12/LandingElementsData.length}>
          <LandingElement data = {leData} key={index} />
        </Grid>
      ))}
    </StyledGrid>
  );
}

export default LandingComposition;