import React from 'react';
import { Typography, Box, Card, CardContent, CardMedia } from '@mui/material';
import './Experiences.css';
import FullSizeSection from '../../styles/FullSizeSection.js';

const experiences = [
  {
    title: 'Experiencia 1',
    description: 'experienceDescription1',
    image: '/images/experience1.jpg',
  },
  {
    title: 'Experiencia 2',
    description: 'experienceDescription2',
    image: '/images/experience2.jpg',
  },
  {
    title: 'Experiencia 3',
    description: 'experienceDescription3',
    image: '/images/experience3.jpg',
  },
];

function Experiences({ backgroundColor }) {
  return (
    <FullSizeSection id="Experiences" style={{ backgroundColor }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Experiencias
      </Typography>
      <Box className="experiences-container">
        {experiences.map((experience, index) => (
          <Box className="experience-item" key={index}>
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                height="140"
                image={experience.image}
                alt={experience.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {experience.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {experience.description}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Box>
      </FullSizeSection>
  );
}

export default Experiences;
