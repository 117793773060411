import React from 'react';
import { useTranslation } from 'react-i18next';
import LandingComposition from '../../util/LandingComposition.js';
import FullSizeSection from '../../styles/FullSizeSection.js';

function Process({ backgroundColor }) {
  const { t } = useTranslation("Process");
  let data = [{
    tittle : t("processTittle"),
    description :t("processMessage"),
    buttonText : t("buttonText"),
    buttonAction : ()=>{console.log("process");}
  }];
  return (
    <FullSizeSection id="process" style={{ backgroundColor }}>
      <LandingComposition LandingElementsData={data} />
    </FullSizeSection>
  );
}

export default Process;