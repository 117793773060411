import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './App.css';
import '../util/i18n'; // Translations
import Description from '../components/Description/Description';
import Experiences from '../components/Experiences/Experiences';
import Process from '../components/Process/Process';
import Footer from '../components/Footer/Footer';
import Schools from '../components/Schools/Schools';
import WhyChooseUs from '../components/WhyChooseUs/WhyChooseUs';
import Menu from '../components/Menu/Menu'; // Import Menu component
import VideoBackground from '../util/BackGroundVideo'

const backgroundImages = [
  '/images/background1.jpg',
  '/images/background2.jpg',
  '/images/background3.jpg',
];

function App() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
    }, 5000); // Change the background image every 5 seconds
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (location.state && location.state.section) {
      const section = document.getElementById(location.state.section);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div className="App">
      <Menu menuItems={[
        { text: 'Descripción', section: 'description' },
        { text: 'WhyChooseUs', section: 'whychooseus' },
        { text: 'Colegios', section: 'schools' },
        { text: 'Experiencias', section: 'experiences' },
        { text: 'Proceso', section: 'process' },
        { text: 'Trabajar en EE.UU.', path: '/work-in-usa' },
        { text: 'Leadership', path: '/leadership' }
        ]} isMainPage={true}/>
      <header
        className="App-header"
        style={{ backgroundImage: `url(${backgroundImages[currentImageIndex]})`, height: '100vh' }}
        id="header"
      >
      <VideoBackground 
        videoSrc="/intro.mp4" 
        title="Proyecto de Enseñanza de Español en EE.UU" 
      />
      </header>

      
      <Description backgroundColor="#00adf1" />
      
      <div id="whychooseus">
        <WhyChooseUs backgroundColor="#00adf1" />
      </div>
      <div id="schools">
        <Schools backgroundColor="#00adf1" />
      </div>
      <div id="experiences">
        <Experiences backgroundColor="#00adf1" />
      </div>
      <div id="process">
        <Process backgroundColor="#00adf1" />
      </div>
      <Footer />
    </div>
  );
}

export default App;
