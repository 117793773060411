import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import '../util/i18n'; // Translations

import Menu from '../components/Menu/Menu'; // Import Menu component
import Footer from '../components/Footer/Footer';

import VideoBackground from '../util/BackGroundVideo'
import { MenuBook } from '@mui/icons-material';
import LandingComposition from '../util/LandingComposition.js';
import FullSizeSection from '../styles/FullSizeSection.js';

const backgroundImages = [
    '/images/background1.jpg',
    '/images/background2.jpg',
    '/images/background3.jpg',
  ];

function About() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
    }, 5000); // Change the background image every 5 seconds
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (location.state && location.state.section) {
      const section = document.getElementById(location.state.section);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const { t } = useTranslation("Description");
  const navigate = useNavigate();
  let data1 = [{
    backgroundImage: "/images/about3.webp"
  },
 {tittle : t("descriptionTittle"),
  description :t("descriptionMessage")
 // backgroundImage: "/images/about3.webp"
 }
  ];

  let data2 = [{
    tittle : t("descriptionTittle"),
    description :t("descriptionMessage"),
    //backgroundImage: "/images/about3.webp"
  },
 {
  backgroundImage: "/images/about3.webp"}
  ];

  let data3 = [{
    backgroundImage: "/images/about3.webp"
  },
 {tittle : t("descriptionTittle"),
  description :t("descriptionMessage"),
  //backgroundImage: "/images/about3.webp"
  }
  ];

  return (
    
    <div className="About">
      <Menu menuItems={[
        { text: 'Descripción', section: 'description' },
        { text: 'WhyChooseUs', section: 'whychooseus' },
        { text: 'Colegios', section: 'schools' },
        { text: 'Experiencias', section: 'experiences' },
        { text: 'Proceso', section: 'process' },
        { text: 'Trabajar en EE.UU.', path: '/work-in-usa' }
        ]}/>
      <header
        className="About-header"
        style={{ backgroundImage: `url(${backgroundImages[currentImageIndex]})`, height: '100vh' }}
        id="header"
      >
       
        <VideoBackground 
        videoSrc="/intro.mp4" 
        title="ABOUT US" 
      />
      </header>
      <FullSizeSection id="description" style={ {}}>
      <LandingComposition LandingElementsData={data1}  />
      <LandingComposition LandingElementsData={data2}  />
      <LandingComposition LandingElementsData={data3}  />
    </FullSizeSection>
      

      <Footer />
    </div>
  );
}

export default About;
