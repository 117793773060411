import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem, Button } from '@mui/material';
import { styled } from '@mui/system';
import './LanguageSelector.css';

const CustomButton = styled(Button)({
  color: '#ffffff', /* Blanco */
  '&:hover': {
    backgroundColor: '#4a5f76', /* Azul grisáceo un poco más oscuro */
    color: '#ffffff', /* Blanco */
  },
});

const CustomMenu = styled(Menu)({
  '.MuiPaper-root': {
    backgroundColor: '#657b93', /* Azul grisáceo oscuro */
  },
  '.MuiMenuItem-root': {
    color: '#ffffff', /* Blanco */
  },
  '.MuiMenuItem-root:hover': {
    backgroundColor: '#4a5f76', /* Azul grisáceo un poco más oscuro */
    color: '#ffffff', /* Blanco */
  },
});

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleClose();
  };

  const languages = { en: "English", es: "Español" };

  return (
    <div className="language-selector">
      <CustomButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {languages[i18n.language]}
      </CustomButton>
      <CustomMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.entries(languages).map(([lng, name]) => (
          <MenuItem key={lng} onClick={() => changeLanguage(lng)}>
            {name}
          </MenuItem>
        ))}
      </CustomMenu>
    </div>
  );
};

export default LanguageSelector;
